const Queries = {
  Account: {
    Media: `
      query AccountMedia($limit: ListLimit!, $filter: AccountMediaFilter){
        account_media(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
          likes like comments description tags song {name} visibility type shop add_date
          approved verified processed {status teaser original info} available_date
        }
      }
    `,
    MediaPrivate: `
      query AccountPrivateMedia($limit: ListLimit!, $filter: AccountMediaFilter){
        private: media_private(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
           likes like comments description tags song {name} visibility type shop available_date
           add_date verified approved processed {status teaser original info} teaser {id url}
        }
      }
    `,
    Auth: `
      query {
        profile: auth_account {
          id fullname avatar username followers phone email
          gender interests live livestream studio {rtmp} posts role{creator} socials{instagram twitter} email_verified
        }
      }
    `,
    LoggedIn: `
      query {
        logged_in {
          context
          account {id fullname avatar username followers phone email
            gender interests live livestream studio {rtmp} posts role{creator} socials{instagram twitter} email_verified}
        }
      }
    `,
    SearchRelation: `query SearchRelation($limit: ListLimit!, $type: String, $filter: AccountFilter) {
        relative_users(filter: $filter, type: $type, limit: $limit) {
          id fullname avatar username
        }
      }`,
    Lives: `query LivesList($limit: ListLimit!) {
        lives(limit: $limit) {
          id fullname avatar username
        }
      }`
  },
  BlockedAccounts: `
    query BlockedAccounts($user: String) {
      accounts_blocked(user: $user) {
        id blocked {id fullname avatar}
      }
    }
  `,
  Creator: {
    Search: `
      query CreatorSearch($filter: AccountFilter, $limit: ListLimit!) {
        creators(filter: $filter, limit: $limit) {
          id fullname avatar username
        }
      }`,
    CreatorRequest: `
      query CreatorRequest {
        creator_request {
          id
          user
          fees {
            subscription
            sales
          }
          status
          add_date
        }
      }
    `
      
  },
  Favorites: {
    Photos: `
      query FavoritePhotos($limit: ListLimit!, $filter: MediaFilter) {
        favoritePhotos(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid} info
          user {
            username authUserSubscription {id}
          }
          visibility type available_date
        }
      }`,
    Videos: `
      query FavoriteVideos($limit: ListLimit!, $filter: MediaFilter) {
        favoriteVideos(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
          user {
            username authUserSubscription {id}
          }
          visibility type available_date
        }
      }`,
  },
  Media: {
    Photos: `
      query PhotoList($limit: ListLimit!, $filter: MediaFilter, $withLike: Boolean!){
        photos(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid} info
          user {
            id fullname avatar username
            isFollowing followers
            authUserSubscription { id } live livestream posts role{creator}
          }
          type shop comments likes available_date add_date
          description tags song {name} visibility
          like @include(if: $withLike) available_date
        }
      }`,
    Videos: `
      query VideoList($limit: ListLimit!, $filter: MediaFilter, $withLike: Boolean!){
        videos(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
          user {
            id fullname avatar username
            isFollowing followers
            authUserSubscription { id } live livestream posts role{creator}
          }
          type shop comments likes available_date add_date
          description tags song {name} visibility
          like @include(if: $withLike) available_date
        }
      }`,
    VideosList:`
      query VideoList(
          $limit: ListLimit!
          $ids: [String!]!
          $short: Boolean!
          $withLike: Boolean!
      ) {
          videos_list(limit: $limit, ids: $ids, short: $short) {
            id source {type url} thumbnail {original preview grid poster} info
            user {
              id fullname avatar username
              isFollowing followers
              authUserSubscription { id } live livestream posts role{creator}
            }
            type shop comments likes available_date add_date
            description tags song {name} visibility
            like @include(if: $withLike) available_date
          }
      }    
    `,
  },
  Profile: {
    Default: `
      query Profile($username: String){
        profile(username: $username) {
          id fullname avatar username followers phone email
          gender interests live livestream posts role{creator} socials{instagram twitter} email_verified
        }
      }`,
    Photos: `
      query ProfilePhotos($username: String, $limit: ListLimit!, $filter: MediaFilter, $isAuth: Boolean!){
        profile(username: $username) {
          id fullname avatar username followers live livestream
          isFollowing @skip(if : $isAuth)
          authUserSubscription @skip(if : $isAuth) { id } posts role{creator}
        }
        photos(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
           likes like comments description tags song {name} visibility type shop available_date
        }
      }`,
    Shop: `
      query ProfileShop($username: String, $limit: ListLimit!, $filter: MediaFilter, $isAuth: Boolean!){
        profile(username: $username) {
          id fullname avatar username followers live livestream 
          isFollowing @skip(if : $isAuth)
          authUserSubscription @skip(if : $isAuth) { id } posts role{creator}
        }
        shop(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
           likes like comments description tags song {name} visibility type shop available_date
        }
      }`,
    Videos: `
      query ProfileVideos($username: String, $limit: ListLimit!, $filter: MediaFilter, $isAuth: Boolean!){
        profile(username: $username) {
          id fullname avatar username followers live livestream
          isFollowing @skip(if : $isAuth)
          authUserSubscription @skip(if : $isAuth) { id } posts role{creator}
        }
        videos(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
           likes like comments description tags song {name} visibility type shop available_date
        }
      }`,
  },
  Relation: {
    Follower: `
      query FollowerList($limit: ListLimit!) {
        followers(limit:$limit){
          id fullname username avatar isFollowing
        }
      }`,
    Following: `
      query FollowingList($limit: ListLimit!) {
        following(limit:$limit){
          id fullname username avatar
        }
      }`,
  },
  References: {
    Photos: `
      query PhotoReferenceList($limit: ListLimit!, $filter: MediaFilter, $username:String){
        photo_reference(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
          likes description tags comments like
          song {name} visibility type available_date
        }
        profile(username: $username){
          id avatar fullname username live livestream
          isFollowing followers
          authUserSubscription { id } role{creator}
        }
      }`,
    Private: `
      query MediaPrivateReferenceList($limit: ListLimit!, $username:String){
        shop_reference(limit: $limit){
          id source {type url} thumbnail {original preview grid poster} info
          likes description tags comments like
          song {name} visibility type shop available_date
        }
        profile(username: $username){
          id avatar fullname username live livestream
          isFollowing followers
          authUserSubscription { id } role{creator}
        }
      }`,
    Videos: `
      query VideoReferenceList($limit: ListLimit!, $filter: MediaFilter, $username:String){
        video_reference(limit: $limit, filter: $filter){
          id source {type url} thumbnail {original preview grid poster} info
          likes description tags comments like
          song {name} visibility type available_date
        }
        profile(username: $username){
          id avatar fullname username live livestream
          isFollowing followers
          authUserSubscription { id } role{creator}
        }
      }`,
  },
  ShortLink: `
    query GetShortLink($id: String!){
      shortLink(id : $id){
        id
        media {id type user{username}}
      }
    }`,
  Tags: `
    query TagsList($limit: MediaTagLimit!, $filter: MediaTagFilter) {
      tags(limit: $limit, filter: $filter) {
        id tags
      }
    }`,
  Sounds: `
    query SoundList($limit: ListLimit!, $filter: SoundFilter) {
      sounds(limit: $limit, filter: $filter) {
        id name url mime_type
      }
    }`,
  LiveChat: `
    query LiveChatMessages($user: String!) {
      livechat(user: $user) {
        id
        user {fullname username avatar}
        text
        add_date
      }
    }`,
  Comments: `
    query MediaComments($limit: ListLimit!, $filter: MediaCommentFilter, $media: String!) {
      mediaComments(limit: $limit, filter: $filter) {
       id media {id} user {username} from {username avatar fullname}
        message thread {id message} add_date
        likes score like
      }
      media_comments_count(media: $media)
    }`,
  Messages: {
    Thread: `
    query Thread($user:String!){
      messenger_thread(user:$user){
        id
      }
    }
    `,
    Threads: `
    query ThreadsList($limit:ListLimit!){
      messenger_threads(limit:$limit){
        id
        users{
            id username fullname avatar
        }
        last_message {
          id data{value type} add_date
        }
        add_date
        unread1
        unread2
        last_update
      }
    }
    `,
    MsgList: `
     query MessagesList($thread:String!,$limit:ListLimit!){
       messenger_messages(thread:$thread,limit:$limit){
        id
        from {
           id fullname
           username avatar
        }
        data{ type value }
     }
    }`,
    RuleEvent: `
    query MessengerRulesEvent($event:String!){
      messenger_rules_event(event: $event) {
        delay {unit amount} messages {type value}
      }
    }`,
    Rules: `
    query MessengerRules($limit:ListLimit!){
      messenger_rules(limit:$limit) {
        delay {unit amount} messages {type value}  event enabled
      }
    }`,
    Templates: `
     query MessagesTemplates($limit:ListLimit!){
       messenger_templates(limit:$limit){
        id
        messages {type value}
     }
    }`,
  },
  Earnings: {
    Unpaid: `query {earnings_unpaid}`,
    FullTransactions: `
      query FullEarningsTransactions($limit:ListLimit!){
        earnings_transactions(limit:$limit){
          id from{id avatar username fullname} type value add_date
        }
        earnings_unpaid
        last_payment {value date}
      }
    `,
    FullPayouts: `
      query FullEarningsPayouts($limit:ListLimit!){
        earnings_payouts(limit:$limit){
          id date value status
        }
        earnings_unpaid
        last_payment {value date}
      }
    `,
    Transactions: `query EarningsTransactions($limit:ListLimit!){
      earnings_transactions(limit:$limit){
        id from{id avatar username fullname} type value add_date
      }
    }`,
    Payouts: `query EarningsPayouts($limit:ListLimit!){
      earnings_payouts(limit:$limit){
        id date value status
      }
    }`,
  },
  SubscriptionDetail: `
  query SubscriptionDetail($user: String!) {
    subscription_detail(user: $user){
      id
      user {
        id
        username
      }
      trial {
        duration
        unit
        price
      }
      period {
        duration
        unit
        price
      }
    }
  }
  `,
  Subscription: {
    Check: `query Subscription($from: String!, $to: String!) {
        subscription(from: $from, to: $to){
          id
        }
      }`
  },
  PasswordReset: {
    PasswordResetRequest: `
      query PasswordResetRequest($id:String!){
        password_reset_request(id:$id){
          user
          add_date
          expiration_date
        }
      }
    `
  },
  Wallet: `query {
    wallet {
      id
      user { id username }
      amount
      cards {
        name
        number
        cvv
        date {
          month
          year
        }
      }
      last_update
    }}`
}

export default Queries;